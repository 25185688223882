$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * viewport events
    // *
    // *
    var offset = 120; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        // animations
        $(".animateIn:visible").each(function () {
            if ($(this).data("animation-offset")) {
                offset = $(this).data("animation-offset");
            }

            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");

            // make effect work in both directions (with class bidirect)
            } else if($(this).hasClass("bidirect")) {
                $(this).removeClass("animateIn--active");
            }
        });

        // countup
        $(".js-countup").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop) <= viewPortEnd && (elementBottom) >= viewPortStart) {

                // check for countup - if in viepoint fire countUp (once)
                if (!$(this).hasClass("counted")) {

                    var options = {
                        useEasing: true,
                        useGrouping: true,
                        separator: '.',
                        decimal: '.',
                    };

                    number = $(this).find(".js-countup-number");

                    number.each(function () {
                        // avoid jumping
                        var width = $(this).width();
                        $(this).css("width", width);

                        var counterID = $(this).attr("id");
                        countTo = $(this).data("count");
                        var count = new CountUp(counterID, 0, countTo, 0, 4, options);
                        count.start();
                    });

                    $(this).addClass("counted");
                }
            }
        });

        // timeline
        $(".js-timeline:visible").each(function () {
            var offset = $(window).height() / 2,
                yearElement = $(this).data("year");

            if ($(window).height() > 900) {
                offset = $(window).height() / 1.5;
            }

            if ($(window).width() < 1200) {
                offset = 500;
            }

            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                $(".js-timeline-year").html(yearElement);
            }
        });
    }

    $(".js-timeline-year").html($(".js-timeline:first-child").data("year"));

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(this).toggleClass("active");
        $("body").toggleClass("freeze");
        $(".js-navmobile").toggleClass("active");
        $(".js-header").toggleClass("navOpen");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *
    // * cloneContent
    // *
    // *
    var cloneContent = $(".js-textimage-origin");

    if (cloneContent.length) {
        cloneContent.each(function () {
            $(this).parents(".js-textimage-wrapper").find(".js-textimage-clone").html($(this).html());
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *
    // * sliderClick
    // *
    // *
    var $sliderClick = $(".js-sliderclick");

    if ($sliderClick.length) {
        $sliderClick.each(function () {
            $(this).slick({
                fade: true,
                infinite: true,
                arrows: false,
                autoplay: false,
                speed: 1000,
                adaptiveHeight: true,
                waitForAnimate: true,
                dots: true,
                appendDots: $(this).parents(".js-sliderclick-wrapper").find(".js-sliderclick-dots"),
                customPaging: function (slick, index) {
                    var targetTitle = slick.$slides.eq(index).find('.js-sliderclick-title').data('title');
                    return '<div class="sliderClick__item">' + targetTitle + '<svg class="sliderClick__icon"><use xlink:href="/assets/templates/web/img/sprite.svg#arrow-right"></use></svg></div>';
                }
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *
    // * mixitup pagination downloads
    // *
    // *

   $(".js-mixitupdownloads").each(function() {

        var mixer = mixitup($(this), {
            selectors: {
                pageList: '.js-mixitupdownloads-pagelist'
            },
            pagination: {
                limit: 6,
                hidePageListIfSinglePage: true
            },
            controls: {
                scope: 'local'
            }

        });
   })




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * GSAP Globals
    // *
    // *
    // smooth scrolling (esp. for safari)
    if ($(window).width() > 700) {
        if ($(".js-smooth-content").length) {
            ScrollSmoother.create({
                content: $(".js-smooth-content"),
                smooth: 1,
                smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
            });
        }
    }

    // Forces scrolling to be done on the JavaScript thread, ensuring screen updates are synchronized and the address bar doesn't show/hide on [most] mobile devices.
    // ScrollTrigger.normalizeScroll(true);

    var gsapHeaderHeight = 0,
        gsapWindowHeight = $(window).height() - 200;

    if ($(window).width() < 700) {
        gsapHeaderHeight = 0;
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * contact / moduleDownload / formdDownload
    // *
    // *
    $(".js-contact-open").click(function () {
        $(".js-contact-modal").toggleClass("active");
        $("body").toggleClass("freeze");
    })

    if($(".js-contact-modal").hasClass("active")) {
        $("body").addClass("freeze");
    }

    $(".js-modaldownload-button").click(function () {
        $(this).parents(".js-modaldownload-wrapper").find(".js-modaldownload").toggleClass("active");
        $("body").toggleClass("freeze");
    })

    $(".js-formdownloadmodal-button").click(function () {
        $(".js-formdownloadmodal").toggleClass("active");
        $("body").toggleClass("freeze");
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * introVideo
    // *
    // *
    var $introVideo = $(".js-introvideo");

    if ($introVideo.length) {
        $introVideo.each(function() {
            var $this = $(this),
                $text = $(this).find(".js-introvideo-text");
                textHeight = $text.outerHeight() * 0.6;

            // trigger animation only for big screens
            gsap.matchMedia().add("(min-width: 700px)", () => {

                var moveHeadlineTimeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: $this,
                        scrub: true,
                        // pin: true,
                        // markers: true,
                        start: "top-=200 top+=" + gsapHeaderHeight,
                        end: "center top+=" + gsapHeaderHeight
                    }
                });

                moveHeadlineTimeline.fromTo($text, {y: - textHeight},{y: 0})

            });


        })
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teaserSticky
    // *
    // *
    var $teaserSticky = $(".js-teasersticky");

    if ($teaserSticky.length) {
        $teaserSticky.each(function() {
            var $this = $(this),
                headline = $(this).find(".js-teasersticky-headline"),
                headlineHeight = headline.outerHeight() * 2;

            // trigger animation only for big screens
            gsap.matchMedia().add("(min-width: 700px)", () => {

                var moveHeadlineTimeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: $this,
                        scrub: 0.1,
                        // pin: true,
                        // markers: true,
                        start: "top-=150 center",
                        end: "bottom center"
                    }
                });

                moveHeadlineTimeline.to(headline, {y: headlineHeight})

            });
        })
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollStage
    // *
    // *
    var $scrollStage = $(".js-stage");

    if ($scrollStage.length) {
        $scrollStage.each(function() {
            var $this = $(this);

            // create timeline for each stage
            var scrollStageTimeline = gsap.timeline({
                scrollTrigger: {
                    trigger: $this,
                    scrub: 0.1,
                    pin: true,
                    // markers: true,
                    start: "top top+=" + gsapHeaderHeight,
                    end: "bottom top+=" + gsapHeaderHeight
                }
            });

            // animate stages - when multiple elements to be animated -> animate them (first loop)
            if ($this.hasClass("stagged")) {
                var $first = $(".js-stage-first"),
                    $second = $(".js-stage-second");

                scrollStageTimeline.fromTo($first, {opacity: 0},{opacity: 1})
                    .fromTo($second, {opacity: 0},{opacity: 1})
                    .to($this, {opacity: 1})
                    .to($this, {opacity: 1})
                    .to($this, {opacity: 1})
                    .to($this, {opacity: 0});
            } else {
                scrollStageTimeline.fromTo($this, {opacity: 0},{opacity: 1})
                    .to($this, {opacity: 1})
                    .to($this, {opacity: 1})
                    .to($this, {opacity: 1})
                    .to($this, {opacity: 0});
            }

            // wrap whole animation and handle snapping with it handle snapping
            // gsap.to(".js-stage-container", {
            //     scrollTrigger: {
            //         trigger: ".js-stage-container",
            //         // snap: {
            //         //     // snapTo: [0.28, 0.86, 0.63, 0.9], // snap to the closest label in the timeline
            //         //     // duration: { min: 0.1, max: 0.4 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
            //         //     // delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
            //         //     // ease: "power1.inOut", // the ease of the snap animation ("power3" by default)
            //         //   },
            //         onUpdate: (self) => {
            //             console.log(self.progress);
            //         },
            //         markers: false,
            //         start: "top top",
            //         end: "bottom bottom"
            //     }
            // });


        })
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * stageFixed
    // *
    // *
    var $stageFixed = $(".js-stagefixed");

    if ($stageFixed.length) {
        $stageFixed.each(function () {
            var $this = $(this);

            gsap.matchMedia().add("(min-width: 701px)", () => {

                ScrollTrigger.create({
                    trigger: $this,
                    scrub: 0.5,
                    pin: true,
                    // markers: true,
                    pinSpacing: false,
                    start: "top top"
                })

            });
        });
    }




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scaleElement
    // *
    // *
    var $scaleElement = $(".js-scaleelement");

    if ($scaleElement.length) {
        $scaleElement.each(function() {
            var $this = $(this);

            var scaleElementTimelline = gsap.timeline({
                scrollTrigger: {
                    trigger: $this,
                    scrub: true,
                    pin: true,
                    // markers: true,
                    start: "top top+=" + gsapHeaderHeight,
                    end: "bottom top+=" + gsapHeaderHeight
                }
            });

            scaleElementTimelline.fromTo($this, {scale: 0.7},{scale: 1})
        })
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  header slideUp
    // *
    // *
    var lastScrollTop = 0;

    $(window).scroll(function(){
        var newScrollTop = $(this).scrollTop();

        if (newScrollTop > lastScrollTop ){
            if ($(this).scrollTop() >= 150) {
                $(".js-header").addClass("slideUp");
            }
        } else {
            $(".js-header").removeClass("slideUp");
        }

        lastScrollTop = newScrollTop;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *
    // * js-image-fader
    // *
    // *
    var $imageFader = $(".js-image-fader");

    if ($imageFader.length) {
        $imageFader.each(function () {
            $(this).slick({
                fade: true,
                infinite: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 3000,
                speed: 750,
                dots: false
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * fetchit
    // *
    // *
    document.addEventListener('fetchit:after', (e) => {
        const { response } = e.detail;

        // console.log(response.success); // true|false
        // console.log(response.message); // Message from the server
        // console.log(response.data); // Data from the server
        if(response.success === true){
           // TODO: Erfolgsnachricht behandeln
            $(".js-success-message").show();
            $(".js-success-form").hide();
        }
      });




});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    var $header = $(".js-header");


    if ($(window).scrollTop() > 150) {
        $header.addClass("header--sticky");
    }

    if ($(window).scrollTop() < 20) {
        $header.removeClass("header--sticky");
    }

});

// * * * * * * * * * * * * * * * * * * * * * * * * *
// * scrollExplosion
// *
// *
$(window).on('load resize', function () {

    var $scrollExplosionPin = $(".js-scrollexplosion-pin");

    if ($scrollExplosionPin.length) {
        $scrollExplosionPin.each(function () {


            if ($(window).width() > 700) {
                var yPosition = $(this).data("y-position");
                $(this).css("top", yPosition + '%');
                $(this).css("left", 'auto');
            } else {
                var yPosition = $(this).data("y-mobile-position");
                var xPosition = $(this).data("x-mobile-position");
                $(this).css("top", yPosition + '%');
                $(this).css("left", xPosition + '%');
            }
        });
    }

    $(".js-scrollexplosion-pin").click(function () {
        $(this).siblings(".js-scrollexplosion-pin").removeClass("active");

        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
        } else {
            $(this).addClass("active");
        }

    })
});


// * * * * * * * * * * * * * * * * * * * * * * * * *
// * invert header
// *
// *
$(window).on("load resize scroll", function() {
    var isAnySectionInView = false,
        headerHeight = 120;

    if ($(window).width() < 700) {
        headerHeight = 80;
    }

    $(".js-invert-section").each(function() {
        var sectionStart = $(this).offset().top - $(window).scrollTop(),
            sectionEnd = $(this).offset().top + $(this).outerHeight() - $(window).scrollTop();

        if (headerHeight > sectionStart && headerHeight < sectionEnd) {
            isAnySectionInView = true;
            return false;
        }
    });

	if (isAnySectionInView) {
        $(".js-header").addClass("header--inverted");
    } else {
        $(".js-header").removeClass("header--inverted");
    }
});


